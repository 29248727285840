<template>
  <div class="my_form">
    <el-form
      :model="ruleForm"
      status-icon
      :rules="rules"
      ref="ruleForm"
      label-width="100px"
      class="demo-ruleForm"
    >
      <el-form-item label="旧密码" prop="oldPass">
        <el-input
          type="password"
          v-model="ruleForm.oldPass"
          autocomplete="off"
          show-password
        ></el-input>
      </el-form-item>
      <el-form-item label="新密码" prop="pass">
        <el-input
          type="password"
          v-model="ruleForm.pass"
          autocomplete="off"
          show-password
        >
         <template v-if="ruleForm.pass" slot="append">{{classTyle}}</template>
        </el-input>
      </el-form-item>
      <el-form-item label="确认密码" prop="checkPass">
        <el-input
          type="password"
          v-model="ruleForm.checkPass"
          autocomplete="off"
          show-password
        ></el-input>
      </el-form-item>

      <el-form-item>
        <el-button type="primary" @click="submitForm('ruleForm')"
          >提交</el-button
        >
        <el-button @click="resetForm('ruleForm')">重置</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
import { modifyPassword } from "@/util/api";
export default {
  data() {
    var validateOldPass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入密码"));
      } else {
        if (this.ruleForm.checkPass !== "") {
          this.$refs.ruleForm.validateField("checkPass");
        }
        callback();
      }
    };
    var validatePass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入密码"));
      } else {
        if (!this.validateString(value)) {
          callback(
            new Error("密码必须包含数字、大小写字母及符号，且长度不能小于8位")
          );
          this.classTyle = "低";
        } else {
          if (value.length < 8) {
            callback(
              new Error("密码必须包含数字、大小写字母及符号，且长度不能小于8位")
            );
            this.classTyle = "低";
          } else if (value.length < 13) {
            this.classTyle = "中";
            if (this.ruleForm.checkPass !== "") {
              this.$refs.ruleForm.validateField("checkPass");
            }
            callback();
          } else {
            this.classTyle = "高";
            if (this.ruleForm.checkPass !== "") {
              this.$refs.ruleForm.validateField("checkPass");
            }
            callback();
          }
        }
      }
    };
    var validatePass2 = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请再次输入密码"));
      } else if (value !== this.ruleForm.pass) {
        callback(new Error("两次输入密码不一致!"));
      } else {
        callback();
      }
    };
    return {
      classTyle:'低',
      ruleForm: {
        oldPass: "",
        pass: "",
        checkPass: "",
      },
      rules: {
        oldPass: [{ validator: validateOldPass, trigger: "blur" }],
        pass: [{ validator: validatePass, trigger: "blur" }],
        checkPass: [{ validator: validatePass2, trigger: "blur" }],
      },
    };
  },
  methods: {
     validateString(str) {
      // 正则表达式，分别检查四个条件
      const hasDigit = /.*\d.*/;
      const hasLowerCase = /.*[a-z].*/;
      const hasUpperCase = /.*[A-Z].*/;
      const hasSpecialChar = /.*[^\da-zA-Z].*/;

      // 检查每个条件是否满足，并计算满足条件的数量
      let count = 0;
      if (hasDigit.test(str)) count++;
      if (hasLowerCase.test(str)) count++;
      if (hasUpperCase.test(str)) count++;
      if (hasSpecialChar.test(str)) count++;
      // 检查是否至少满足三个条件
      return count >= 3;
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          modifyPassword({
            newPassword: this.ruleForm.pass,
            oldPassword: this.ruleForm.oldPass,
            domainUrl: window.location.hostname,
          }).then((res) => {
             if(res.data.code == 500){
              this.$message.warning(res.data.msg)
              return
            }
            this.$notify({
              title: "成功",
              message: "修改成功",
              type: "success",
            });
            this.$router.replace("/user/myInfo");
          });
        } else {
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
  },
};
</script>
<style lang="scss" scoped>
.my_form {
  width: 60%;
  margin: 20% auto;
}
</style>
